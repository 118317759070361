<template>
  <div class="container">
    <div class="two-sided">
      <div>
        <p>
          Welcome to our Shop!
        </p>
        <p>
          Based on the guiding principles of our <router-link :to="{ name: 'concept' }">ecosystem restoration project
          </router-link>
          we offer "the surplus" for sale while we work on creating abundance on the land.
        </p>
        <p>
          You will find here what is available at any given moment. Proceeds from the shop help us to sustain the
          project.
        </p>
      </div>

      <figure v-if="hasPorkMeatSales || hasBeefSales" id="butcher-shop-logo">
        <router-link :to="{ name: 'butcherShop' }"><img src="@/assets/images/butcherShop.jpg" /></router-link>
        <figcaption>{{ $t("mainMenu.butcherShop") }}</figcaption>
      </figure>
    </div>

    <h2>Own Products from Granja Caimito</h2>
    <div class="products">
      <div v-for="item in enabledProducts" :key="item.id">
        <figure>
          <a :href="`/shop/product/${item.productId}`"><img :src="`/img/shop/${item.productId}.jpg`"></a>
          <figcaption>{{ item.productName }}</figcaption>
        </figure>
      </div>
    </div>

    <h2>Products from our neighbors at Valle de los Pedroches
    </h2>
    <p>Not yet available.</p>

  </div>
</template>

<script>
import { setupFeatureFlags } from '@/library'
import { axiosInstance } from '@/library'

export default {
  name: "Shop",
  components: {
  },

  setup () {
    return setupFeatureFlags()
  },

  data: () => ({
    productsAvailable: [],
  }),

  computed: {
    enabledProducts () {
      return this.productsAvailable.filter(product => product.enabled)
    },
  },

  beforeMount () {
    this.listProducts()
  },

  methods: {
    listProducts () {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/sales/inventory`)
        .then(response => {
          if (response.status === 200) {
            this.productsAvailable = response.data.inventory
          }
        })
        .catch(console.error)
    },
  }

};
</script>

<style scoped>
figure {
  padding: 0;
  margin: 0;
}

@media (max-width:440px) {
  figure {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

figcaption {
  display: table-caption;
  caption-side: bottom;
  padding-bottom: 1em;
  text-align: center;
  color: black;
  background-color: white;
  position: relative;
  height: 17px;
  bottom: 44px;
  width: 250px;
  padding-top: 8px;
  opacity: 0.8;
}

DIV.products {
  display: grid;
  grid-template-columns: repeat(4, 270px);
}

@media (max-width:440px) {
  DIV.products {
    display: flex;
    flex-direction: column;
  }
}

figure IMG {
  width: 250px;
}

.products A {
  color: black;
}

DIV.fresh-products {
  display: grid;
  grid-template-columns: auto auto;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width:440px) {
  DIV.fresh-products {
    display: flex;
    flex-direction: column;
  }
}
</style>
